import styles from "./style.module.css";
import {useMemo} from "react";
import useGoogleAnalyticsEvent from "../../../../hooks/UseGoogleAnalyticsEvent";
import {t} from "../../../../intl/Intl";

export type ContactCardProps = {
    location: string;
    name: string;
    phone: string;
    email: string;
    position?: string;
    specialization?: string;
}

const ContactCard = ({location, name, phone, email, position = undefined, specialization = undefined}: ContactCardProps) => {

    const logEventContactClickPhone = useGoogleAnalyticsEvent('sales_contact_click_phone', 'contact')
    const logEventContactClickEmail = useGoogleAnalyticsEvent('sales_contact_click_email', 'contact')

    const markerSrc = useMemo(() =>  '/images/marker.svg', []);

    const phoneLink = useMemo(() => {
        return "tel:" + phone.replace(/ /g, '');
    }, [phone]);

    const emailLink = useMemo(() => {
        return "mailto:" + email;
    }, [email]);

    return (
        <div className={styles.container}>
            <div className={styles.locationContainer}>
                <div className={styles.markerContainer}>
                    <img src={markerSrc} alt={t('locationString')} className={styles.marker} width={24} height={24} fetchpriority={"high"}/>
                </div>
                <div className={styles.location}>{location}</div>
            </div>
            <div className={styles.infoContainer}>
                <div className={styles.name}>{name}</div>
                {position && <div className={styles.position}>{position}</div>}
                <div className={styles.phone}>{t('phoneString')}: <a href={phoneLink} className={styles.colorSecondary} onClick={logEventContactClickPhone}>{phone}</a></div>
                <div className={styles.email}>{t('emailString')}: <a href={emailLink} className={styles.colorSecondary} onClick={logEventContactClickEmail}>{email}</a></div>
                {specialization && <div className={styles.specialization}>{specialization}</div>}
            </div>
        </div>
    )
}

export default ContactCard